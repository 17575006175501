export default function(type = 'video') {

  return {
    track(text, url) {
      window.dataLayer = window.dataLayer || [];

      if(type === 'video') {
        console.log(`Tracking video click event - ${text}`);
        window.dataLayer.push({
          'event': 'video_link_click',
          'video_name': text,
          'video_link': url
        });
        return;
      }

      if(type === 'podcast') {
        console.log(`Tracking podcast click event - ${text}`);
        window.dataLayer.push({
          'event': 'podcast_link_click',
          'podcast_name': text,
          'podcast_link': url
        });
      }
    }
  };

}
